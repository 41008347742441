// Font Family 

$font1: 'Poppins', sans-serif;

$font2: 'Montserrat', sans-serif;

$font3: 'Courier Prime', monospace;

$font4: 'Smudger LET', normal;

$font5: 'Dosis', sans-serif;

// Colors
$white: #fff;

$text: #7a808d;

$title: #333;

$theme_color: #F49922;


// Responsive Variables
$laptop: 'only screen and (min-width: 1200px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';