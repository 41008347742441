// Deneb Cta css
.zorry_cta{
	.cta_wrapper{
		padding: 45px 50px 42px;
		max-width: 970px;
		border-radius: 15px;
		margin: auto;
		margin-bottom: 120px;
		position: relative;
		background-image: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
		background-image: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
		background-image: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
		box-shadow: 2.5px 4.33px 15px 0px rgba(254, 176, 0, 0.4);
		z-index: 1;
		&:after{
			content: '';
		    background: url(../images/shape/cta_shape.png) no-repeat;
		    background-position: bottom;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    z-index: -1;
		}
		
		.cta_content{
			h3{
				@media #{$xs}{
					font-size: 24px;
				}
				color: #333;
				font-weight: bold;
				&:after{
    				content: '';
				    display: block;
				    background: url(../images/shape/line_2.png) no-repeat;
				    max-width: 110px;
				    height: 2px;
				    margin-top: 13px;
				    margin-bottom: 24px;
    			}
			}
			p{
				color: #000;
			}
		}
		.button_box{
			@media #{$md}{
				float: none;
				text-align: left;
				margin-top: 30px;
			}
			@media #{$xs}{
				float: none;
				text-align: center;
				margin-top: 30px;
			}
			float: right;
			.zorry_btn{
				background: $white;
				color: #F49922;
				&:hover,
				&:focus{
					box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
					color: #F49922;
				}
			}
		}
	}
	.cta_wrapper2{
	padding: 45px 50px 42px;
	max-width: 970px;
	border-radius: 15px;
	margin: auto;
	margin-bottom: 120px;
	position: relative;
	background-image: -moz-linear-gradient( 0deg, rgb(26, 97, 134) 0%, rgb(26, 97, 134) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(67, 168, 233) 0%, rgb(67, 168, 233) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(67, 168, 233) 0%, rgb(67, 168, 233) 100%);
	box-shadow: 2.5px 4.33px 15px 0px rgb(26, 97, 134);
	z-index: 1;
	&:after{
		content: '';
		background: url(../images/shape/cta_shape.png) no-repeat;
		background-position: bottom;
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	.cta_content{
		h3{
			@media #{$xs}{
				font-size: 24px;
			}
			color: #333;
			font-weight: bold;
			&:after{
				content: '';
				display: block;
				background: url(../images/shape/line_2.png) no-repeat;
				max-width: 110px;
				height: 2px;
				margin-top: 13px;
				margin-bottom: 24px;
			}
		}
		p{
			color: #000;
		}
	}
	.button_box{
		@media #{$md}{
			float: none;
			text-align: left;
			margin-top: 30px;
		}
		@media #{$xs}{
			float: none;
			text-align: center;
			margin-top: 30px;
		}
		float: right;
		.zorry_btn{
			background: $white;
			color: #1d5983;
			&:hover,
			&:focus{
				box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
				color: #1d5983;
			}
		}
	}}
}

// zorry_achivement
.zorry_achivement{
	padding-bottom: 120px;
	position: relative;
	z-index: 1;
	.section_title{
		@media #{$md}{
			margin: 0;
			margin-bottom: 60px;
		}
	}
	&:after{
		position: absolute;
		content: '';
		right: 25px;
		top: 40px;
		background: url(../images/shape/shape_9.png) no-repeat;
		background-position: top right;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	.zorry_content_box{
		p{
			margin-bottom: 20px;
		}
	}
	.achivment_wrapper{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		position: relative;
		z-index: 9999;
		@media #{$md}{
			justify-content: center;
		}
		@media #{$xs}{
			justify-content: center;
		}
		.single_achivment{
			@media #{$lg}{
				width: 180px;
    			height: 180px;
    			padding-top: 30px;
			}
			@media #{$xs}{
				margin: auto;
				margin-bottom: 40px;
				&:nth-child(even){
	    			margin-top: 0;
	    		}
			}
			width: 214px;
  			height: 214px;
  			border-radius: 50%;
  			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
  			text-align: center;
    		padding-top: 40px;
    		margin-left: 40px;
    		background: $white;
    		border: 3px dashed $white;
    		@include transition(.5s);
    		&:hover{
    			border-color: $theme_color;
    			box-shadow: 5px 8.66px 22px 0px rgba(0, 0, 0, 0.15);
    		}
    		&:nth-child(even){
    			margin-top: 35px;
    		}
    		.icon{
    			margin-bottom: 15px;
    		}
    		h3{
    			margin-bottom: 5px;
    			color: $theme_color;
    			font-weight: 600;
    		}
		}
	}
}
.zorry_achivement{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: -10px;
			}
		}
	}
}
// zorry_breadcrumb
.zorry_breadcrumb{
	padding: 215px 0 60px;
	.breadcrumb_content{
		h1{
			@media #{$xs}{
				font-size: 36px;
			}
		}
		ul{
			li{
				display: inline-block;
				color: $theme_color;
				text-transform: uppercase;
				font-weight: bold;
				&:after{
					content: '\f105';
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					margin-left: 10px;
					margin-right: 10px;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
				a{
					text-transform: uppercase;
					color: $theme_color;
				}
			}
		}
	}
}

// zorry_pagination
.zorry_pagination{
	ul{
		li{
			display: inline-block;
			margin-left: 5px;
			margin-right: 5px;
			a{
				@media #{$xs}{
					width: 30px;
					height: 30px;
					line-height: 30px;
				}
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 50%;
  				background-color: rgb(238, 238, 238);
  				color: #626667;
  				font-size: 15px;
  				font-weight: 500;
  				@include transition(.5s);
  				&:hover,&:focus{
  					color: $white;
			  		background-image: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
					background-image: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
					background-image: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
  				}
			}
		}
		.prev,.next{
			a{
				@media #{$xs}{
					width: 45px;
  					height: 30px;
				}
				display: block;
				width: 85px;
  				height: 40px;
  				border-radius: 30px;
			}
		}
	}
}
// zorry_sidebar
.zorry_sidebar{
	.widget{
		margin-bottom: 50px;
		.widget-title{
			font-weight: bold;
			margin-bottom: 20px;
			&:before{
				content: '';
			    display: inline-block;
			    width: 5px;
			    height: 30px;
			    background: #ffb40c;
			    margin-right: 10px;
			    vertical-align: middle
			}
		}
	}
	.widget_search{
		.form_group{
			position: relative;
			.form_control{
				border-radius: 30px;
				padding: 15px 20px 15px 40px;
				background: $white;
				box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.2);
			}
			.fa-search{
				position: absolute;
				left: 20px;
				top: 15px;
			}
			.search_btn{
				background: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				background: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				background: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				position: absolute;
				top: 0;
				right: 0;
				border: none;
				width: 93px;
				height: 45px;
				color: $white;
				border-radius: 30px;
			}
		}
	}
	.widget_admin{
		.admin_box{
			img{
				border-radius: 8px;
				margin-bottom: 15px;
			}
			p{
				margin-bottom: 25px;
			}
		}
	}
	.widget_archive,
	.widget_categories{
		ul{
			li{
				padding: 7px 8px 7px 20px;
    			border-radius: 19px;
				margin-bottom: 10px;
				@include transition (.5s);
				cursor: pointer;
				&:hover{
					box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
					& a{
						& span{
							color: $white;
							background: $theme_color;
						}
					}
				}
				a{
					color: #7f8693;
					span{
						@include transition (.5s);
						float: right;
						display: block;
						width: 45px;
						height: 25px;
						line-height: 25px;
						text-align: center;
						background: #eeeeee;
						border-radius: 30px;
					}
				}
			}
		}
	}
	.featured_news{
		.post_wrap{
			padding: 10px;
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			border-radius: 10px;
			&:hover{
				box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
			}
			&:last-child{
				margin-bottom: 0;
			}
			.post_img{
				img{
					max-width: 128px;
					height: 108px;
					border-radius: 8px;
				}
			}
			.post_info{
				margin-left: 15px;
				h3{
					font-weight: 500;
					font-size: 18px;
					margin-bottom: 10px;
					a{
						color: #626667;
					}
				}
				p{
					color: $theme_color;
				}
			}
		}
	}
	.widget_tag_cloud{
		ul{
			li{
				display: inline-block;
				margin-right: 10px;
				margin-bottom: 15px;
				&:last-child{
					margin-bottom: 0;
				}
				a{
					display: block;
					padding: 3px 10px 3px 3px;
					border: 1px solid #ccc;
					border-radius: 30px;
					color: #8e8e8f;
					span{
						display: inline-block;
						width: 24px;
						height: 24px;
						line-height: 24px;
						text-align: center;
						border-radius: 50%;
						background: #cccccc;
						color: #ccc;
						margin-right: 10px;
						@include transition(.5s);
					}
					&:hover,
					&:focus{
						span{
							color: $white;
							background-image: -moz-linear-gradient( 90deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -webkit-linear-gradient( 90deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -ms-linear-gradient( 90deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
						}
					}
				}
			}
		}
	}
}