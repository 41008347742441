// zorry_service
.zorry_service{
	position: relative;
	.grid_item{
		max-width: 370px;
		margin: auto;
		padding: 50px 45px 30px;
		text-align: center;
		border-radius: 8px;
		@include transition(.5s);
		border: 2px dashed $white;
		&:hover{
			border-color: $theme_color;
			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
		}
		.icon{
			margin-bottom: 30px;
		}
		.info{
			h4{
				@media #{$lg}{
					font-size: 18px;
				}
				margin-bottom: 15px;
				font-weight: 600;
				a{
					color: $title;
					font-weight: 600;
				}
			}
			p{
				margin-bottom: 20px;
			}
			.link_btn{
				font-weight: 500;
				color: $title;
				text-transform: uppercase;
			}
		}
	}
}
.service_v1{
	padding-top: 125px;
	padding-bottom: 90px;
}
// service_wrapper
.service_wrapper{
	.service_tab_wrap{
		.service_tab_nav{
			margin-bottom: 80px;
			.nav-tabs{
				justify-content: center;
				border: none;
				.nav-item{
					margin-left: 12px;
					margin-right: 12px;
					@media #{$lg}{
						margin-bottom: 20px;
					}
					@media #{$md}{
						margin-bottom: 20px;
					}
					@media #{$xs}{
						margin-bottom: 20px;
					}
					.nav-link{
						border: none;
						text-align: center;
					    font-size: 18px;
					    font-weight: 600;
					    color: #011a3e;
					    width: 170px;
					    height: 170px;
					    background: #fff;
					    box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
					    border-radius: 8px;
					    padding: 20px 25px;
					    border: 3px dashed $white;
					    @include transition (.5s);
						& img{
							margin-bottom: 10px;
						}
						& span{
							display: block;
						}
						&.active{
							border-color: $theme_color;
							box-shadow: 5px 8.66px 27px 0px rgba(0, 0, 0, 0.15);
						}
					}
				}
			}
		}
		.tab-content{
			.zorry_content_box{
				@media #{$md}{
					margin-bottom: 60px;
				}
				h2{
					position: relative;
					margin-bottom: 30px;
				}
				p{
					margin-bottom: 20px;
				}
			}
			.zorry_img_box{
				position: relative;
				z-index: 1;
				img{
					@media #{$lg}{
						width: 100%;
					}
					@media #{$xs}{
						width: 100%;
					}
					border-radius: 8px;
					max-width: 540px;
					box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.3);
				}
				&:after{
					@media #{$xs}{
						display: none;
					}
					position: absolute;
					right: 0;
					top: -30px;
					content: '';
					width: 94%;
					height: 397px;
					z-index: -1;
					border-radius: 8px;
					background-image: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
					background-image: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
					background-image: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				}
			}
		}
	}
}
.service_wrapper{
	.zorry_content_box{
		h2{
			position: relative;
			&:after{
			    position: absolute;
			    content: '';
			    left: -30px;
			    top: -20px;
			    background: url(../images/shape/title.png) no-repeat;
			    display: inline-block;
			    width: 96px;
			    height: 67px;
			    z-index: -1;
			}
		}
	}
}
.service_feature{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: 70px;
			}
		}
	}
}
// zorry_feature
.service_feature{
	padding-bottom: 100px;
	.feature_wrapper{
		.single_feature{
			.zorry_img_box{
				@media #{$md}{
					margin-bottom: 60px;
				}
			}
			.zorry_content_box{
				
				
				.icon{
					display: inline-block;
					width: 100px;
					height: 100px;
					line-height: 100px;
					text-align: center;
					border-radius: 50%;
					background-color: $white;
					box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
					margin-bottom: 30px;
				}
				h3{
					position: relative;
					font-size: 30px;
					font-weight: bold;
					margin-bottom: 10px;
				}
			}
			&:nth-child(odd){
				.zorry_content_box{
					@media #{$md}{
						margin-bottom: 60px;
					}
				 max-width: 60%;
					text-align: left;
				}
			}
			&:nth-child(even){
				.zorry_content_box{
					@media #{$md}{
						margin-bottom: 60px;
					}
					max-width: 60%;
					text-align: right;
					float: right;
				}
			}
		}
	}
}
// service_page
.service_page{
	.zorry_pricing{
		padding-bottom: 105px;
	}
}
.service_v1{
	.section_title{
		h2{
			position: relative;
			&:after{
				left: 120px;
			}
		}
	}
}