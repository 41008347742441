// zorry_pricing
.zorry_pricing{
	.pircing_box{
		@media #{$md}{
			margin-bottom: 60px;
		}
		@media #{$xs}{
			margin-bottom: 60px;
		}
		padding: 35px;
		text-align: center;
		border-radius: 8px;
		background-color: rgb(255, 255, 255);
		box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
		border: 3px dashed $white;
		@include transition(.5s);
		&:hover{
			border-color: $theme_color;
			box-shadow: 5px 8.66px 24px 0px rgba(0, 0, 0, 0.15);
		}
		.icon{
			margin-bottom: 30px;
		}
		.title{
			margin-bottom: 30px;
			h3{
				font-weight: bold;
				color: $title;
			}
		}
		.list{
			margin-bottom: 25px;
		}
		.price{
			margin-bottom: 25px;
			h2{
				font-size: 40px;
				span{
					font-size: 20px;
					color: $theme_color;
				}
			}
		}
		.button_box{
			.zorry_btn{
				min-width: 158px;
			}
		}
	}
}
.zorry_pricing{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: 40px;
			}
		}
	}
}
// pricing_v1
.pricing_v1{
	position: relative;
	padding-bottom: 120px;
}