// Start Default css

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('../fonts/montserrat-v25-latin-regular.woff') format('woff'),
	   url('../fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local('../fonts/montserrat-v25-latin-500.woff') format('woff'),
	   url('../fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local('../fonts/montserrat-v25-latin-600.woff') format('woff'),
	   url('../fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local('../fonts/montserrat-v25-latin-700.woff') format('woff'),
	   url('../fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('../fonts/poppins-v20-latin-regular.woff') format('woff'),
	   url('../fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('../fonts/poppins-v20-latin-500.woff') format('woff'), 
	   url('../fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('../fonts/poppins-v20-latin-700.woff') format('woff'),
	   url('../fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* courier-prime-regular - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/courier-prime-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('../fonts/courier-prime-v7-latin-regular.woff') format('woff'),
	   url('../fonts/courier-prime-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/courier-prime-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/courier-prime-v7-latin-regular.svg#CourierPrime') format('svg'); /* Legacy iOS */
}
/* courier-prime-italic - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/courier-prime-v7-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('../fonts/courier-prime-v7-latin-italic.woff') format('woff'),
	   url('../fonts/courier-prime-v7-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/courier-prime-v7-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-italic.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/courier-prime-v7-latin-italic.svg#CourierPrime') format('svg'); /* Legacy iOS */
}
/* courier-prime-700 - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/courier-prime-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('../fonts/courier-prime-v7-latin-700.woff') format('woff'),
	   url('../fonts/courier-prime-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/courier-prime-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-700.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/courier-prime-v7-latin-700.svg#CourierPrime') format('svg'); /* Legacy iOS */
}
/* courier-prime-700italic - latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/courier-prime-v7-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('../fonts/courier-prime-v7-latin-700italic.woff') format('woff'),
	   url('../fonts/courier-prime-v7-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/courier-prime-v7-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-700italic.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/courier-prime-v7-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/courier-prime-v7-latin-700italic.svg#CourierPrime') format('svg'); /* Legacy iOS */
}

/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/dosis-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('../fonts/dosis-v27-latin-regular.woff') format('woff'),
	   url('../fonts/dosis-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/dosis-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/dosis-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/dosis-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/dosis-v27-latin-regular.svg#Dosis') format('svg'); /* Legacy iOS */
}
/* dosis-700 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/dosis-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local('../fonts/dosis-v27-latin-700.woff') format('woff'),
	   url('../fonts/dosis-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	   url('../fonts/dosis-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	   url('../fonts/dosis-v27-latin-700.woff') format('woff'), /* Modern Browsers */
	   url('../fonts/dosis-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	   url('../fonts/dosis-v27-latin-700.svg#Dosis') format('svg'); /* Legacy iOS */
}

*{
	margin: 0;
	padding: 0;
	outline: 0;
}
html{
	font-size: 100%;
}
a{
	text-decoration: none;
	&:hover,
	&:focus{
		text-decoration: none;
		outline: none;
	}
}
h1,h2,h3,h4,h5,h6{
	margin: 0;
	padding: 0;
	font-family: $font2;
	color: $title;
}
h1{
	font-size: 58px;
	font-weight: 500;
	font-family: $font3;
}
h2{
	font-size: 45px;
	font-weight: 700;
	font-family: $font2;
}
h3{
	font-size: 30px;
	font-family: $font2;
}
h4{
	font-size: 22px;
}
h5{
	font-size: 20px;
}
h6{
	font-size: 16px;
}
p{
	margin: 0;
	padding: 0;
}
ul{
	margin: 0;
	padding-left: 20px;
	li{
	list-style-position: outside;
	}
}
input,
select,
textarea{
	display: inherit;
	border: none;
	outline: none;
}
button{
	border: none;
	cursor: pointer;
}
button{
	outline: none;
	&:hover,
	&:focus{
		outline: none;
	}
}
body{
	font-family: $font1;
	font-size: 21px;
	line-height: 1.5em;
	color: $text;
	font-weight: normal;
	overflow-x: hidden;
}
.bg_image{
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.section_padding{
	padding: 100px 0;
}
.section-ptb {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section_title{
	max-width: 570px;
	margin: auto;
	margin-bottom: 45px;
	h2{
		@media #{$xs}{
			font-size: 32px
		}
		margin-bottom: 14px;
		position: relative;
		&:after{
		    position: absolute;
		    content: '';
		    top: -20px;
		    background: url(../images/shape/title.png) no-repeat;
		    display: inline-block;
		    width: 96px;
		    height: 67px;
		    z-index: -1;
		}
	}
}
.zorry_btn{
	display: inline-block;
	min-width: 150px;
	height: 45px;
	color: $white;
	font: 500 15px $font1;
	text-align: center;
	background: $theme_color;
	line-height: 45px;
	border-radius: 23px;
	text-transform: uppercase;
	letter-spacing: 1px;
	@include transition(.5s);
	&:hover,&:focus{
		color: $white;
		box-shadow: 2.5px 4.33px 15px 0px rgba(254, 176, 0, 0.4);
	}
}
.link_btn{
	color: $title;
	&:before{
		display: inline-block;
		content: '\f04b';
		font-weight: 900;
		font-family: "Font Awesome 5 Free";
		margin-right: 10px;
		color: $theme_color;
	}
	&:hover,&:focus{
		color: $theme_color;
	}
}
.form_control{
	width: 100%;
}
input.form_control{
	height: 45px;
}
@media #{$laptop}{
	.container{
	    max-width: 1200px;
	}
}
@media #{$xs}{
	.zorry_img{
		img{
			width: 100%;
		}
	}
}
// preloader

.preloader{
	background: $white;
	height: 100%;
	overflow: hidden;
	position: fixed;
	text-align: center;
	width: 100%;
	z-index: 11000;
	.lds-ripple {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%,-50%));
	  	display: inline-block;
	  	width: 80px;
	  	height: 80px;
	  	div{
	  		position: absolute;
			border: 4px solid $theme_color;
			opacity: 1;
			border-radius: 50%;
			animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			&:nth-child(2){
			  	animation-delay: -0.5s;
			}
	  	}
	}
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
