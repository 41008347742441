// Deneb Footer css
.zorry_footer{
	.widget_wrapper{
	    background-repeat: no-repeat;
	    background-size: cover;
	    padding-top: 200px;
	    padding-bottom: 70px;
	    .widget{
	    	@media #{$xs}{
				margin-bottom: 40px;
			}
	    	.widget_title{
	    		margin-bottom: 30px;
	    		h4{
	    			font-weight: bold;
	    			&:after{
	    				content: '';
					    display: block;
					    background: url(../images/shape/line.png) no-repeat;
					    max-width: 38px;
					    height: 2px;
					    margin-top: 5px;
	    			}
	    		}
	    	}
	    }
	    .widegt_about{
	    	p{	font-size: 16px;
	    		margin-bottom: 20px;
	    	}
	    	.social{
	    		li{
	    			display: inline-block;
	    			margin-right: 10px;
	    			a{
	    				display: block;
					    width: 30px;
					    height: 30px;
					    line-height: 30px;
					    text-align: center;
					    border-radius: 50%;
					    background-color: #f9e6d4;
					    color: #fba543;
					    font-size: 14px;
					    @include transition( all .5s);
					    &:hover,&:focus{
							background-image: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
					    	color: $white;
					    	box-shadow: 2.5px 4.33px 15px 0px rgba(254, 176, 0, 0.4);
					    }
	    			}
	    		}
	    	}
	    }
	    .widget_link{
	    	ul{
	    		li{
	    			margin-bottom: 5px;
	    			a{
	    				text-transform: capitalize;
	    				color: $text;
	    				&:hover,
	    				&:focus{
	    					color: $theme_color;
	    				}
	    			}
	    		}
	    	}
	    }
	    .widget_contact{
	    	.contact_info{
	    		.single_info{
	    			max-width: 250px;
	    			display: flex;
		    		flex-wrap: wrap;
		    		margin-bottom: 5px;
		    		.icon{
		    			font-size: 12px;
		    			color: #333;
		    			margin-right: 10px;
		    		}
		    		.info{
		    			p{ 
							font-size: 16px;
		    				& a{
		    					color: $text;
		    				}
		    				& span{
			    				display: block;
			    			}
		    			}
		    		}
	    		}
	    	}
	    }
	}
	.copyright_area{
		background: #edecf0;
		padding: 10px 0;
		.copyright_text{
			text-align: center;
			p{ font-size: 16px;
				color: $title;
				span{
					color: $theme_color;
				}
			}
		}
	}
}